import React from 'react';
import { useOkBreakable } from './insert-context';

const Breakable = React.memo(() => {
  const okBreakable = useOkBreakable();

  return okBreakable ? (
    <div
      dangerouslySetInnerHTML={{
        __html: `<div class="div-breakable"></div>`,
      }}
    />
  ) : null;
});

export default Breakable;
