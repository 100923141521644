import React, { createContext, useContext, ReactNode, useMemo, useCallback } from 'react';

export type InsertType = ReactNode[];
export type InsertContextType = {
  incrementCurHeading: () => void;
  decrementCurHeading: () => void;
  curHeading?: number;
  insertHeadings: InsertType;
  cacheInsertHeadings: {
    [key: string]: number;
  };
};

const InsertContext = createContext<InsertContextType>({} as InsertContextType);

export const useInsertContext = () => {
  const context = useContext(InsertContext);

  return context;
};

export const useInsertHeadingContext = (vid: string) => {
  const context = useContext(InsertContext);

  const curHeading = useMemo(() => {
    if (context?.cacheInsertHeadings && context?.cacheInsertHeadings[vid] !== undefined) {
      return context.cacheInsertHeadings[vid];
    }

    if (context.cacheInsertHeadings) {
      const cur = context.curHeading;
      context.cacheInsertHeadings[vid] = cur;
      context.incrementCurHeading();

      return cur;
    }

    return null;
  }, [context, vid]);

  if (context.insertHeadings && context.insertHeadings[curHeading]) {
    return context.insertHeadings[curHeading];
  }

  return null;
};

export const useOkBreakable = () => {
  const context = useContext(InsertContext);

  return context.curHeading >= context?.insertHeadings?.length;
};

interface InsertContextProviderProps {
  insertHeadings: InsertType;
  children: ReactNode;
}

export const InsertContextProvider: React.FC<InsertContextProviderProps> = ({ insertHeadings, children }) => {
  const value: InsertContextType = useMemo(
    () => ({
      cacheInsertHeadings: {},
      insertHeadings,
      curHeading: 0,
      incrementCurHeading: () => null,
      decrementCurHeading: () => null,
    }),
    [insertHeadings],
  );

  value.incrementCurHeading = useCallback(() => {
    value.curHeading += 1;
  }, [value]);

  value.decrementCurHeading = useCallback(() => {
    value.curHeading -= 1;
  }, [value]);

  return <InsertContext.Provider value={value}>{children}</InsertContext.Provider>;
};
