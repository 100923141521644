import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export const useInfiniteScroll = (handleBecameVisible: () => void) => {
  const arriveInView = useRef<boolean>(false);
  const { ref, inView } = useInView({
    rootMargin: '75%',
  });

  useEffect(() => {
    if (inView && !arriveInView.current) {
      arriveInView.current = true;
      handleBecameVisible();
    }
  }, [handleBecameVisible, inView]);

  if (!inView) {
    arriveInView.current = false;
  }

  return ref;
};
