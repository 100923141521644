import React from 'react';
import { HeadingTwo as HeadingTwoDesign } from '@marty-js/design/src/atoms/h2';
import type { HeadingData, PluginComponent } from './types';
import { useInsertHeadingContext } from '../../insert-context';

export const HeadingTwo: PluginComponent = ({ children, data, vid }) => {
  const headingData = data as HeadingData;
  const insert = useInsertHeadingContext(vid);

  return (
    <>
      {insert}
      <HeadingTwoDesign id={headingData?.index}>{children}</HeadingTwoDesign>
    </>
  );
};
