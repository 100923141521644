import React, { useCallback, useMemo, useState } from 'react';
import { GetServerSideProps } from 'next';
import { getApiClient, getServerSideCache } from '@marty-js/api-sdk';
import { usePageInfo } from '@marty-js/api-sdk/services/pageInfo';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { fetchPage } from '@marty-js/api-sdk/services/page';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import { PageType } from '@marty-js/api-sdk/__generated__/globalTypes';
import dynamic from 'next/dynamic';
import { Spinner } from '@marty-js/sdk/src/utils/spinner';
import { useMetaSeoContext } from '@marty-js/sdk/src/utils/metaSeoContext';
import { useDatalayer } from '@marty-js/sdk/src/utils/AnalyticsContext';
import consoleDebug from '@marty-js/sdk/src/utils/consoleDebug';
import AdScript from '@marty-js/sdk/src/ads/AdScript';
import { config as siteConfig } from '../config';
import { DefaultLayout } from '../modules/layout/default-layout';
import { useClubicAds, useOverrideAdUnit } from '../modules/ads/use-clubic-ads';
import MicroData from '../modules/layout/microData';

const PageContent = dynamic(() => import('@marty-js/sdk/src/page-content/page-content'));

const MainContainer = styled.main`
  background-color: var(--theme-color-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 var(--spacer-s);
  position: relative;
  transition: background 1s;
  width: 100vw;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: grid;
      flex-direction: initial;
      grid-template-columns: 60px 240px 60px 60px 1fr 60px 60px 240px 60px;
      padding: var(--spacer-s) var(--spacer-s) 0;
      width: initial;
    `,
  )}
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  width: 100%;
  height: 100%;
`;

type Props = {
  url: string;
};

const Slug = ({ url }: Props) => {
  const { loading: infoLoading, pageInfo } = usePageInfo({ variables: { url }, pollInterval: 60000 });
  const { currentTheme } = useThemeSwitcher();
  const [overrideAdUnit, setOverrideAdUnitState] = useState<string>(null);
  const callOverrideAdUnit = useOverrideAdUnit();
  const setOverrideAdUnit = useCallback(
    (val: string) => {
      if (val !== overrideAdUnit) {
        callOverrideAdUnit(val);
        setOverrideAdUnitState(val);
      }
    },
    [overrideAdUnit, setOverrideAdUnitState, callOverrideAdUnit],
  );

  useClubicAds(pageInfo, overrideAdUnit);

  const canonical =
    pageInfo?.metaSeo.canonical === '/' ? siteConfig.baseUrl : `${siteConfig.baseUrl}${pageInfo?.metaSeo.canonical}`;

  useDatalayer({
    url: canonical,
    breadcrumb: pageInfo?.breadcrumb.slice(-1)[0]?.url ?? pageInfo?.breadcrumb.slice(-1)[0]?.title,
  });

  const metaSeoContext = useMetaSeoContext();

  const imageUrl = useMemo(() => {
    switch (pageInfo?.pageType) {
      case PageType.item:
        return `https://pic.clubic.com/v1/images/${pageInfo.item.imageId}/raw`;
      case PageType.section:
        return `https://pic.clubic.com/v1/images/${pageInfo.section.imageId ?? '1876284'}/raw`;
      default:
        return 'https://pic.clubic.com/v1/images/1876284/raw';
    }
  }, [pageInfo]);

  const authors = useMemo(() => {
    switch (pageInfo?.pageType) {
      case PageType.item:
        return pageInfo.item.linkedWith?.author;
      default:
        return null;
    }
  }, [pageInfo]);

  const dateItem = useMemo(() => {
    switch (pageInfo?.pageType) {
      case PageType.item:
        return {
          publishedAt: pageInfo.item.publishedAt,
          republishedAt: pageInfo.item.republishedAt,
          updatedAt: pageInfo.item.updatedAt,
        };
      default:
        return null;
    }
  }, [pageInfo]);

  useMemo(() => {
    metaSeoContext.title = pageInfo?.metaSeo?.title;
    metaSeoContext.titleH1 = pageInfo?.metaSeo?.titleH1;
    metaSeoContext.pageTitle = pageInfo?.title;
    metaSeoContext.description = pageInfo?.metaSeo?.description;
    metaSeoContext.authors = authors;
    metaSeoContext.imageUrl = imageUrl;
    metaSeoContext.date = dateItem;
    metaSeoContext.url = canonical;
    metaSeoContext.canonical = canonical;
    metaSeoContext.robots = pageInfo?.metaSeo?.robots || 'noindex, nofollow';
    metaSeoContext.layout = pageInfo?.layout;
  }, [pageInfo, authors, imageUrl, dateItem, canonical, metaSeoContext]);

  consoleDebug({ dataSize: pageInfo?.data.length });

  const jsonData = useMemo(() => (pageInfo?.data ? JSON.parse(pageInfo?.data) : null), [pageInfo?.data]);

  return (
    <DefaultLayout data={jsonData}>
      <MainContainer>
        <MicroData microData={pageInfo?.microData} />
        {useMemo(
          () =>
            infoLoading ? (
              <SpinnerContainer>
                <Spinner width={50} height={50} color={currentTheme.colors.primary} />
              </SpinnerContainer>
            ) : (
              <PageContent pageInfo={pageInfo} data={jsonData} set0verrideAdUnit={setOverrideAdUnit} />
            ),
          [currentTheme.colors.primary, infoLoading, pageInfo, jsonData, setOverrideAdUnit],
        )}
      </MainContainer>
      <AdScript pageContentLoaded={!infoLoading} />
    </DefaultLayout>
  );
};

export const getServerSideProps: GetServerSideProps<Props, { slug: string[] }> = async (context) => {
  const slug = context.params?.slug || [];
  const url = `/${slug.join('/')}${'trailingSlash' in context.req ? '/' : ''}`;
  const apiClient = getApiClient();
  const { notFound, redirectionUrl, permanent, pageType, dataLayout, layout } = await fetchPage(apiClient, url);

  // @ts-ignore
  if (context.req._sentrySpan?._spanContext) {
    // @ts-ignore
    context.req._sentrySpan._spanContext.customTransaction = `[...slug]: ${pageType} -> ${dataLayout}${
      dataLayout === layout ? '' : ` (${layout})`
    }`;
  }

  if (notFound) {
    return {
      notFound: true,
    };
  }

  if (redirectionUrl) {
    return {
      redirect: {
        destination: redirectionUrl,
        permanent,
      },
    };
  }

  context.res.setHeader('Cache-Control', 'max-age=30, public, stale-while-revalidate=7200');

  context.res.setHeader(
    'Link',
    '<//api.clubic.com>; rel=preconnect, ' +
      '<//pic.clubic.com>; rel=preconnect, ' +
      '</assets-react/fonts/bitter.woff2>; rel=preload; as=font; type=font/woff2; crossorigin="anonymous", ' +
      '</assets-react/fonts/inter-regular.woff2>; rel=preload; as=font; type=font/woff2; crossorigin="anonymous", ' +
      '</assets-react/fonts/inter-bold.woff2>; rel=preload; as=font; type=font/woff2; crossorigin="anonymous", ',
  );

  return {
    props: { url, ...getServerSideCache(apiClient) },
  };
};

export default Slug;
