import { AuthorType } from '@marty-js/api-sdk/types';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export type MetaSeoType = {
  title?: string;
  pageTitle?: string;
  ogTitle?: string;
  twitterTitle?: string;
  titleH1?: string;
  url?: string;
  description?: string;
  imageUrl?: string;
  authors?: AuthorType[];
  pageNumber?: number;
  alternateUrls?: { fr: string; en: string };
  date?: { publishedAt: string; republishedAt: string; updatedAt: string };
  canonical?: string;
  robots?: string;
  layout?: string;
};

const MetaSeoContext = createContext<MetaSeoType>({});

export const useMetaSeoContext = () => {
  return useContext(MetaSeoContext);
};

export const useSetMetaTitle = (title: string) => {
  const context = useContext(MetaSeoContext);
  context.title = title;
};

export const useSetMetaTitleIfNull = (title: string) => {
  const context = useContext(MetaSeoContext);
  if (!context.title) {
    context.title = title;
  }
};

export const useSetPageNumber = (page: number) => {
  const context = useContext(MetaSeoContext);
  context.pageNumber = page;
};

export function MetaSeoProvider({ children, value }: PropsWithChildren<{ value: MetaSeoType }>) {
  return <MetaSeoContext.Provider value={value}>{children}</MetaSeoContext.Provider>;
}
