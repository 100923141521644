import { gql } from '@apollo/client';

export const GENERIC_ITEM = gql`
  fragment GenericItem on GenericItem {
    id
    type
    title
    publishedAt
    republishedAt
    updatedAt
    officialReleasedAt
    approximateReleasedAt
    mainVariantName
    lead
    tagSlugs
    layout
    item {
      title
      id
    }
    commentsNb
    authors {
      id
      url
      title
    }
    userRating
    userRatingNb
    imageId
    url
    price {
      price
      link
      stock
      model
      preDiscountPrice
      expirationDate
      specificConditions
      intPrice
      popularity
      shop {
        icon
        name
      }
    }
    productReviewScore
    dataSheet {
      itemTitle
      groups {
        name
        shortSlug
        attributes {
          shortSlug
          topSpec
          slug
          iconSlug
          type
          value
          url
          name
        }
      }
    }
  }
`;

export const ITEM_FRAGMENT = gql`
  fragment Item on PageInfo {
    item {
      id
      title
      popularity
      downloadCount
      imageId
      url
      lead
      sponsors {
        title
        url
        order
      }
      medias {
        mediaType
        src
        order
        main
      }
      tagSlugs
      userRating
      userRatingNb
      ratingConclusion
      linkedWith {
        author {
          id
          title
          imageId
          summary
          url
          mainDataSheet {
            groups {
              name
              shortSlug
              attributes {
                shortSlug
                topSpec
                iconSlug
                type
                url
                value
                name
              }
            }
          }
        }
      }
      variants {
        id
        variant
        officialReleasedAt
        approximateReleasedAt
        dataSheet {
          groups {
            shortSlug
            attributes {
              shortSlug
              type
              url
              value
              name
            }
          }
        }
        prices {
          price
          link
          intPrice
          condition
          shop {
            icon
            name
          }
        }
      }
      mainDataSheet {
        tag {
          name
        }
        groups {
          shortSlug
          name
          attributes {
            shortSlug
            topSpec
            iconSlug
            type
            url
            value
            name
          }
        }
      }
      comments
      externalLink
      updatedAt
      publishedAt
      republishedAt
      label
    }
  }
`;
